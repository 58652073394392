<template>
  <v-app id="inspire">
    <v-app-bar app color="white" elevation="10">
      <v-container class="py-0 fill-height">
        <v-btn x-small fab color="secondary" circle link to="/">
          <span class="white--text subtitle">{{ supplier.capital }}</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="secondary"
          v-for="link in links"
          :key="link.name"
          link
          :to="link.url"
          text
        >
          {{ link.name }}
        </v-btn>

        <v-spacer></v-spacer>

        <v-icon
          v-if="current_user != null"
          color="secondary"
          large
          @click="on_avatar_clicked"
          >mdi-account-circle</v-icon
        >
        <v-icon v-else large @click="on_avatar_clicked"
          >mdi-account-circle</v-icon
        >
      </v-container>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <router-view :key="view_key" v-bind:login_ok="login_ok"></router-view>
    </v-main>

    <v-footer color="white" elevation="10">
      <v-col class="text-center caption" cols="12">
        Powered by
        <v-avatar color="secondary" size="16">
          <span class="white--text subtitle">{{ supplier.capital }}</span>
        </v-avatar>
        {{ supplier.name }}
        <a
          href="https://beian.miit.gov.cn"
          class="black--text"
          v-if="vendor.icp_id != null"
          >{{ vendor.icp_id }}</a
        >
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { supplier } from "/static/js/config.js";
import { vendor } from "/static/js/config.js";
import axios from "axios";

export default {
  components: {},
  data: () => ({
    supplier: supplier,
    vendor: vendor,
    links: [
      // {
      //   name: "Home",
      //   url: "/",
      // },
      {
        name: "Blog",
        url: "/blog",
      },
      {
        name: "App",
        url: "/app",
      },
    ],
    // current_user: {
    //   name: 'Jay'
    // },
    current_user: null,
  }),
  computed: {
    view_key() {
      return this.$route.name !== undefined
        ? this.$route.name + new Date()
        : this.$route + new Date();
    },
    login_ok() {
      if (this.current_user != null) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    get_user_info() {
      axios.post("/backend/user/current").then((response) => {
        var status = response.data.status;
        if (status == 0) {
          this.current_user = response.data.data;
        } else {
          this.current_user = null;
        }
      });
    },
    on_avatar_clicked() {
      if (this.current_user != null) {
        this.$router.push({
          path: "/user/home",
        });
      } else {
        this.$router.push({
          path: "/user/login",
        });
      }
    },
  },
  mounted() {
    this.get_user_info();
  },
};
</script>
